import React from 'react';

function VisionSection() {
  return (
    <section id="vision" className="section vision-section">
      <div className="container">
        <h2 className="section-title">Our Vision</h2>
        <p>
          At Friction Studios, we're passionate about pushing the boundaries of interactive entertainment.
          Our team is hard at work creating immersive worlds, compelling stories, and innovative gameplay
          mechanics that will redefine the gaming landscape.
        </p>
        <div className="values">
          <ValueItem
            icon="💡"
            title="Innovation"
            description="Pushing the boundaries of what's possible in gaming."
            color="#3498db"
          />
          <ValueItem
            icon="🎨"
            title="Creativity"
            description="Bringing unique and captivating ideas to life."
            color="#2ecc71"
          />
          <ValueItem
            icon="🎮"
            title="Player-Centric"
            description="Focusing on creating unforgettable experiences for gamers."
            color="#e67e22"
          />
        </div>
      </div>
    </section>
  );
}

function ValueItem({ icon, title, description, color }) {
  return (
    <div className="value-item">
      <div className="value-icon" style={{ backgroundColor: color }}>{icon}</div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default VisionSection;