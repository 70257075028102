import React, { useState } from 'react';
import getAccessToken from './authService'; // Ensure the path is correct
import axios from 'axios';

const ContactSection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus('');

    try {
      const accessToken = await getAccessToken();

      const emailContent = {
        message: {
          subject: "New Message from Friction Studios Contact Form",
          body: {
            contentType: "Text",
            content: `Name: ${name}\nEmail: ${email}\n\nMessage: ${message}`,
          },
          toRecipients: [
            {
              emailAddress: {
                address: "admin@friction-studios.com",
              },
            },
          ],
          replyTo: [
            {
              emailAddress: {
                address: email,
                name: name
              }
            }
          ]
        },
        saveToSentItems: false
      };

      await axios.post(
        "https://graph.microsoft.com/v1.0/users/admin@friction-studios.com/sendMail",
        emailContent,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      setStatus('Message sent successfully! We\'ll get back to you soon.');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Failed to send email:', error.response ? error.response.data : error.message);
      setStatus('Failed to send message. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section id="contact" className="contact-section">
      <div className="container">
        <h2 className="section-title">Contact Us</h2>
        <p className="section-description">
          Have questions or want to collaborate? Reach out to us! We'd love to hear from you.
        </p>
        <form className="contact-form" onSubmit={sendEmail}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Your Email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Type your message here"
              rows="5"
            />
          </div>
          <button type="submit" className="submit-button" disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        {status && <p className={`status-message ${status.includes('successfully') ? 'success' : 'error'}`}>{status}</p>}
      </div>
    </section>
  );
};

export default ContactSection;