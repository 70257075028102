import React from 'react';

function Header() {
  return (
    <header className="header">
      <div className="container">
        <a href="/" className="logo">
          <svg viewBox="82.17 137.778 242.292 67.183" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0" style={{stopColor:"#00AEEF", stopOpacity:1}} />
                <stop offset="1" style={{stopColor:"#0077BE", stopOpacity:1}} />
              </linearGradient>
            </defs>
            <g transform="matrix(1.2000000476837158, 0, 0, 1.2000000476837158, 140.77969360351562, 105.53137969970703)">
              <path d="M 26.852 26.872 L 56.852 26.872 L 41.852 56.872 L 26.852 26.872 Z" fill="url(#blueGradient)" />
              <path d="M 41.852 26.872 L 71.852 26.872 L 56.852 56.872 L 41.852 26.872 Z" fill="#4D4D4D" />
            </g>
            <text x="191.785" y="199.071" className="logo-text" textAnchor="middle" style={{whiteSpace: "pre", fontFamily: "Arial, sans-serif", fontSize: "28px", fontWeight: 700, lineHeight: "44.8px", perspectiveOrigin: "70.6016px 30px"}} transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)">
              <tspan fill="#333333" style={{fontSize: "27.7px", wordSpacing: "0px"}}>Friction</tspan>
              <tspan fill="#00AEEF" style={{fontSize: "27.7px", wordSpacing: "0px"}}>Studios</tspan>
            </text>
          </svg>
        </a>
        <nav>
          <a href="#vision">Our Vision</a>
          <a href="#open-positions">Open Positions</a>
          <a href="#contact">Contact</a>
          <a href="#newsletter">Newsletter</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;