import axios from 'axios';

const getAccessToken = async () => {
  try {
    const response = await axios.post('https://friction-studios.com/get-token'); // Change to the backend URL
    return response.data.access_token;
  } catch (error) {
    console.error('Error getting access token:', error);
    throw error;
  }
};

export default getAccessToken;
