import React, { useState } from 'react';

function NewsletterSection() {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the email to your backend
    console.log('Submitted email:', email);
    setEmail('');
  };

  return (
    <section id="Newsletter" className="newsletter">
      <div className="container">
        <h2 className="section-title">Stay Updated</h2>
        <p>Join our newsletter to receive the latest news and updates from Friction Studios.</p>
        <form className="newsletter-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">SUBSCRIBE</button>
        </form>
      </div>
    </section>
  );
}

export default NewsletterSection;