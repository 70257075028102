import React from 'react';

function HeroSection() {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>IGNITING NEW WORLDS</h1>
        <p>Friction Studios is crafting the next generation of gaming experiences.</p>
        <button className="cta-button">JOIN OUR JOURNEY</button>
      </div>
    </section>
  );
}

export default HeroSection;