import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import VisionSection from './components/VisionSection';
import NewsletterSection from './components/NewsletterSection';
import Footer from './components/Footer';
import ContactSection from './components/ContactSection';
import OpenPositionsSection from './components/OpenPositionsSection';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <HeroSection />
        <VisionSection />
        <OpenPositionsSection />
        <ContactSection />
        <NewsletterSection />
      </main>
      <Footer />
    </div>
  );
}

export default App;