const msalConfig = {
    auth: {
      clientId: process.env.CLIENT_ID,
      authority: "https://login.microsoftonline.com/"+process.env.TENANT_ID,
      redirectUri: "http://friction-studios.com", // or your production URL
    },
  };
  
  const loginRequest = {
    scopes: ["Mail.Send"],
  };
  
  export { msalConfig, loginRequest };
  