import React, { useState, useEffect } from 'react';
import positionsData from '../positions.json';
import getAccessToken from './authService'; // Make sure this path is correct
import axios from 'axios';

function OpenPositionsSection() {
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [application, setApplication] = useState({
    name: '',
    email: '',
    resume: null,
    coverLetter: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    setPositions(positionsData.positions);
  }, []);

  const handleApply = (position) => {
    setSelectedPosition(position);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApplication(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setApplication(prev => ({ ...prev, resume: e.target.files[0] }));
  };

  const sendEmail = async (to, subject, body, attachments = []) => {
    const accessToken = await getAccessToken();
    const emailContent = {
      message: {
        subject,
        body: {
          contentType: "HTML",
          content: body
        },
        toRecipients: [
          {
            emailAddress: {
              address: to,
            }
          }
        ],
        attachments
      }
    };

    await axios.post(
      "https://graph.microsoft.com/v1.0/users/admin@friction-studios.com/sendMail",
      emailContent,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus('');

    try {
      // Convert resume file to base64
      const reader = new FileReader();
      reader.readAsDataURL(application.resume);
      reader.onload = async () => {
        const resumeBase64 = reader.result.split(',')[1];

        // Send email to company
        await sendEmail(
          "admin@friction-studios.com",
          `New Application for ${selectedPosition.title}`,
          `
            <h2>New Job Application</h2>
            <p><strong>Position:</strong> ${selectedPosition.title}</p>
            <p><strong>Name:</strong> ${application.name}</p>
            <p><strong>Email:</strong> ${application.email}</p>
            <h3>Cover Letter:</h3>
            <p>${application.coverLetter}</p>
          `,
          [
            {
              "@odata.type": "#microsoft.graph.fileAttachment",
              "name": application.resume.name,
              "contentType": application.resume.type,
              "contentBytes": resumeBase64
            }
          ]
        );

        // Send confirmation email to applicant
        await sendEmail(
          application.email,
          "Application Received - Friction Studios",
          `
            <h2>Application Received</h2>
            <p>Dear ${application.name},</p>
            <p>Thank you for your application for the ${selectedPosition.title} position at Friction Studios. We have received your application and will review it shortly.</p>
            <p>Here's a summary of your application:</p>
            <ul>
              <li><strong>Position:</strong> ${selectedPosition.title}</li>
              <li><strong>Name:</strong> ${application.name}</li>
              <li><strong>Email:</strong> ${application.email}</li>
            </ul>
            <p>We appreciate your interest in joining our team and will be in touch if we need any additional information or to schedule an interview.</p>
            <p>Best regards,<br>The Friction Studios Team</p>
          `
        );

        setStatus('Application submitted successfully! Please check your email for a confirmation.');
        setApplication({ name: '', email: '', resume: null, coverLetter: '' });
        setShowModal(false);
      };
    } catch (error) {
      console.error('Failed to send application:', error.response ? error.response.data : error.message);
      setStatus('Failed to submit application. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section id="open-positions" className="open-positions-section">
      <div className="container">
        <h2 className="section-title">Open Positions</h2>
        <ul className="positions-list">
          {positions.map((position) => (
            <li key={position.id} className="position-item">
              <h3>{position.title}</h3>
              <p>{position.description}</p>
              <p><strong>Location:</strong> {position.location}</p>
              
              {/* Requirements Section */}
              {position.requirements && position.requirements.length > 0 && (
                <>
                  <h4>Requirements:</h4>
                  <ul>
                    {position.requirements.map((req, index) => (
                      <li key={index}>{req}</li>
                    ))}
                  </ul>
                </>
              )}

              {/* Nice-to-Have Section (optional) */}
              {position.nice_to_have && position.nice_to_have.length > 0 && (
                <>
                  <h4>Nice-to-Have:</h4>
                  <ul>
                    {position.nice_to_have.map((nice, index) => (
                      <li key={index}>{nice}</li>
                    ))}
                  </ul>
                </>
              )}

              <button onClick={() => handleApply(position)} className="apply-button">Apply</button>
            </li>
          ))}
        </ul>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Apply for {selectedPosition.title}</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={application.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={application.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="resume">Resume:</label>
                <input
                  type="file"
                  id="resume"
                  name="resume"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="coverLetter">Cover Letter:</label>
                <textarea
                  id="coverLetter"
                  name="coverLetter"
                  value={application.coverLetter}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-actions">
                <button type="submit" className="submit-button" disabled={isLoading}>
                  {isLoading ? 'Submitting...' : 'Submit Application'}
                </button>
                <button type="button" onClick={() => setShowModal(false)} className="cancel-button">Cancel</button>
              </div>
            </form>
            {status && <p className={`status-message ${status.includes('successfully') ? 'success' : 'error'}`}>{status}</p>}
          </div>
        </div>
      )}
    </section>
  );
}

export default OpenPositionsSection;